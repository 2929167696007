<template>
  <div>
    <HomeworksWidget :dates="dates" v-if="!isElementaryStudent"></HomeworksWidget>
    <v-divider class="mx-5"></v-divider>
    <ManualHomeworksWidget :dates="dates"></ManualHomeworksWidget>
  </div>
</template>

<script>
import HomeworksWidget from '@/view/pages/dashboard_widgets/HomeworksWidget'
import ManualHomeworksWidget from '@/view/pages/dashboard_widgets/ManualHomeworksWidget'
import ApiService from '@/core/services/api.service'

export default {
  name: 'MyHomeworks.vue',
  components: { ManualHomeworksWidget, HomeworksWidget },
  data() {
    return {
      dates: [],
      isElementaryStudent: null
    }
  },
  created() {
    this.getUserFromApi()
  },
  methods: {
    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
        .then((data) => {
          this.isElementaryStudent = data.data.IsElementaryStudent
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
